.weekday-padding
  padding-top: 4px

.value
  height: 40px
  border: 1px solid #6D7A96

.ibtn
  height: 40px
  width: 40px

.title-pricing
  text-align: center

td.small-input-pricing
  input[type="text"]
      width: 80px
      border: none
      outline: none
      box-shadow: none
      font-size: $text
      background-color: #D2D6E0
      border-radius: 5px
