.dropdown 
  position: relative
  display: inline-block
  .dropdown-chevron
    position: absolute
    right: 10px
    top: 4px
  .dropbtn
    padding: 6px 10px
    border-radius: 5px
    outline: none
    border: 1px solid $input-border
    background-color: #fff
    width: 100%
    text-align: left
    font-size: 18px
    height: 40px
    cursor: pointer
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    position: relative
  .dropdown-content 
    position: absolute
    background-color: #f9f9f9
    width: 100%
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
    max-height: 400px
    overflow: auto
    cursor: pointer
    z-index: 900
    ul
      margin-bottom: 0px
  .wrap-field-dropdown
    position: relative
    span
      position: absolute
      top: 8px
      right: 15px
      .drop-icon
        display: block

.dropdown-content 
  li 
    color: black
    padding: 12px 16px
    text-decoration: none
    display: block
  li:hover 
    background-color: #EBE7DF
  li.active 
    transition: ease-in 0.2s
    background-color: $primary-green
    color:#fff


