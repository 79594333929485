$omena-green: #285557
$border-color: #eee
#alm-weekdays
  >div
    margin-right: 32px
    display: inline-block

.allotment-list-hotel
  span
    div
      margin-right: 50px
      display: inline-block

.date-zone
  width: 100%

.date-container
  width: 100%
  white-space: nowrap
  overflow-y: hidden
  overflow-x: auto
  scroll-behavior: smooth
  .alm-date 
    display: inline-block
    width: 96px
    border-right: 1px solid #EEEEEE
    padding: 8px
    height: 130px
    &:last-child
      border-right: 1px solid #EEEEEE
    .date 
      padding-top: 4px
      height: 26px
      text-align: center
      width: 100%
      margin: 0
    input[type=number]
      -moz-appearance: textfield
    input
      width: 100%
      height: 40px
      text-align: center
      background-color: #fff
      line-height: 30px
      border: 1px solid $input-border
      border-radius: 6px
      outline: none
      padding: 8px
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button 
        -webkit-appearance: none
        margin: 0
        pointer-events: none
    &.weekend
      background-color: #F8F7F2

.allotment-action-button
  width: 160px

.allotment-info
  display: -webkit-flex
  display: flex
  border: 1px solid $primary-light-grey
  border-radius: 5px
  box-shadow: 3px 3px $primary-light-grape
  padding: 0 !important
  .info-block
    border-right: 1px solid $primary-light-grey
    padding: 10px 20px 0px 20px
    cursor: pointer
    .name
      font-weight: bold
      .created-date
        font-weight: normal
        font-style: italic
        color: $primary-dark-grey
        float: right
  .sub-info-block
    display: -webkit-flex
    display: flex
    align-items: center
    justify-content: center
    border-right: 1px solid $primary-light-grey
    .type
      flex: 1
      text-align: center
      font-weight: bold
      &.type-hard
        color: $primary-green
      &.type-soft
        color: $primary-blue
  .action-block
    display: flex
    cursor: pointer
    align-items: center
    justify-content: center
    .go-to-edit
      flex: 1
      color: $primary-green
      text-align: center
      font-size: 2.1em

.allotment-detail-info
  border-radius: 5px
  margin-bottom: 30px
  padding: 10px 20px
  &.grey-border
    border: 1px solid $primary-light-grey

.day-restrict-as-checkbox
  width: 15px
  height: 15px
  border: 1px solid $primary-dark-grey
  margin-right: 7px
  display: inline-block
  &.weekend
    outline: 1px solid $primary-light-grape
  &.checked
    background-color: $primary-green

.day-restrict-as-text
  display: inline-block
  margin-right: 12px
  color: $primary-black
  opacity: 0.2
  &.weekend
    background-color: $primary-light-grape
  &.checked
    opacity: 1

.cancelled-text
  color: $primary-alert

.allotment-voucher-title
  color: $primary-green

.allotment-voucher-block
  padding: 5px

.allotment
  a
    background-color: #EEEEEE
    border-radius: 6px 6px 0px 0px !important
    padding: 8px 8px !important
    
    height: 40px !important
    margin-right: 4px !important
    font-size: 16px
    line-height: 24px
.allotment-active
  a
    background-color: $primary-green !important
    color: $primary-white !important
.amount-of-room-soft-allotment
  padding-bottom: 45px

.amount-of-room-hard-allotment
  padding-bottom: 0px

.available-rooms
  padding-bottom: 40px

.show-available-rooms
  padding-bottom: 0px

.time-btn
  border-top-right-radius: 5px !important
  border-bottom-right-radius: 5px !important

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
   -webkit-appearance: "Always Show Up/Down Arrows"
   opacity: 2
   height: 35px
   width: 35px

.format-btn-edit-HA
  padding-right: 2px
  padding-left: 2px

.disabled
  pointer-events: none
  opacity: 0.6

.label-format
  width: 16%