.fade-in
	animation-name: fade-in
	-webkit-animation-name: fade-in	
	animation-duration: 0.5s	
	-webkit-animation-duration: 0.75s
	animation-timing-function: ease	
	-webkit-animation-timing-function: ease	
	visibility: visible !important						


@keyframes fade-in 
	0% 
		opacity: 0
	100% 
		opacity: 1

@-webkit-keyframes fade-in  
	0% 
		opacity: 0
	100% 
		opacity: 1
@-webkit-keyframes spin 
	0% 
		-webkit-transform: rotate(0deg)
	100% 
		-webkit-transform: rotate(360deg)

@keyframes spin 
	0% 
		-webkit-transform: rotate(0deg)
	100% 
		-webkit-transform: rotate(360deg)