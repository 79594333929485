.table-bordered
  th
    color: #424F68
    background-color:  #F8F7F2
  td
    &.blank
      background-color: $primary-dark-grey
    &.active
      background-color: $primary-light-blue !important
      input[type="text"]
        background-color: $primary-light-blue
    input[type="text"]
      width: 130px
      border: none
      outline: none
      box-shadow: none
      font-size: $text
      background-color: #D2D6E0
      border-radius: 5px
    .cell-symbol
      padding-right: 10px
    input:disabled
        background-color: inherit

td.small-input
  input[type="text"]
      width: 90px
      border: none
      outline: none
      box-shadow: none
      font-size: $text
      background-color: #D2D6E0
      border-radius: 5px    

td.small-input        
  input[type="number"]
    min-width: 55px  
    width: 100%
    border: none
    outline: none
    box-shadow: none
    font-size: $text
    background-color: #D2D6E0
    border-radius: 5px
      
td.small-input        
  lable
      width: 30px   
