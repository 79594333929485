.margin-left-right-15
  margin: 0 15px
@media (min-width: 992px)
  .select-report
    width: 33.333333% !important
.breakfast-report-dialog-body
  display: flex
  flex-direction: row
.select-hotel
  width: 30%
.set-email
  width: 70%
  padding-left: 15px
.date-field[disabled]
  opacity: 0.65