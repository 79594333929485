.btn
  font-size: 13pt
  outline: none !important
  height: 40px
  &.full-width
    width: 100% !important
  &:not(.dropdown-toggle)
    border-radius: 5px!important
    .custom-fa
      margin-right: 5px
      svg
        max-height: 18px
    &.icon-only
      .custom-fa
        margin-right: 0
  &.small-text
    font-size: 11pt
    .custom-fa
      top: -2px
      svg
        max-height: 15px
  &.label-offset
    position: relative
    top: 38px
  &.btn-compact
    padding: 0px 5px
    height: auto
.btn-red,
.btn-green,
.btn-orange
  color: $primary-white !important
  fill: $primary-white
.btn-green
  background-color: $primary-green !important
.btn-red
  background-color: $primary-red !important
.btn-outline-green
  color: $primary-green !important
  background-color: transparent !important
  border: 1px solid $primary-green !important
  fill: $primary-green
.btn-none-outline-green
  color: $primary-green !important
  background-color: transparent !important
  text-decoration: underline
  border: none !important
  fill: $primary-green
.btn-outline-red
  color: $primary-red !important
  background-color: transparent !important
  border: 1px solid $primary-red !important
  fill: $primary-red
.btn-yellow
  background: $primary-yellow!important
  color: $primary-green!important
  fill: $primary-green
.btn-orange
  background: $primary-orange!important
.btn-outline-yellow
  background: $primary-green
  color: $primary-yellow!important
  border: 1px solid $primary-yellow!important
  border-radius: 6px
  fill: $primary-yellow
.btn-none
  background-color: transparent!important
  border: none!important
  padding: 0
  color: $primary-green
  fill: $primary-green
  &.icon-only
    font-size: 18pt
.look-like-a-link
  background: none!important
  color: inherit
  border: none
  padding: 0!important
  font: inherit
  cursor: pointer
  &:hover
    span
      border-bottom: 1px solid
.datepicker-input
  position: relative
  display: flex
  align-items: center
  border: 1px solid $input-border
  border-radius: 5px
  background: #fff
  input
    flex: 1
    width: 100%
    border: none
    background: transparent
    font-size: 18px
    padding: 0 10px
    line-height: 1.2
  .datepicker-btn
    flex: 0 0 40px
    width: 40px
    height: 40px
    border: none
    border-radius: 0 5px 5px 0
    fill: $primary-green
    vertical-align: middle
    background: transparent