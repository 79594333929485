.margin-as-label
  margin-top: 27px

.hotel-selector
  width: 100%
  text-align: center

#reason 
  height: 125px
  resize: none

.hotel-picker[disabled]
  opacity: 1

.text-area-custom-scrollbar
  &::-webkit-scrollbar-track
    border-radius: 5px
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar:hover
    background-color: $primary-light-grey
  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: darken($primary-light-grey, 5%)

.no-text-selection
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none