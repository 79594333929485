.text-input
  height: 40px
  padding: 10px 20px
  font-size: $title
  box-shadow: none
  &:focus
    box-shadow: none

.text-input-small
  height: 32px
  padding: 5px 10px
  border: 1px solid $primary-dark-grey
  font-size: $text
  box-shadow: none
  &:focus
    border-color: $primary-dark-grey
    box-shadow: none

.input-group-btn>.btn
  z-index: 2
  &:hover, &:focus, &:active
    z-index: 2

.dropdown
  width: 100%
  .dropdown-btn
    position: relative
    height: 40px
    padding: 6px 20px 6px 10px
    border: 1px solid $primary-dark-grey
    border-radius: 5px !important
    text-overflow: ellipsis !important
    overflow: hidden
    color: $font-primary-grey
    font-size: $text-small
    line-height: 24px
    &:hover
      color: $font-primary-grey
    &:focus
      outline: none
      box-shadow: none
  .dropdown-btn-small
    position: relative
    height: 32px
    padding: 6px 20px 6px 10px
    border: 1px solid $primary-dark-grey
    border-radius: 5px !important
    text-overflow: ellipsis !important
    overflow: hidden
    color: $font-primary-grey
    font-size: $title
    &:hover
      color: $font-primary-grey
    &:focus
      outline: none
      box-shadow: none
  .dropdown-list
    padding-top: 5px
    .dropdown-item
      padding: 5px
      &:hover
        background-color: $primary-extra-light-gray
        cursor: pointer

.datepicker
  .datepicker-input
    border-top-left-radius: 4px !important
    border-bottom-left-radius: 4px !important
    &:focus
      border-color: $primary-dark-grey
      outline: none
      box-shadow: none
    &:active
      box-shadow: none
  &.small
    .datepicker-input
      height: 38px
    .datepicker-btn
      height: 38px
      padding: 7px 15px
  .datepicker-btn
    &:focus
      color: $primary-white
      outline: none
      box-shadow: none
    &:active, &:visited
      color: $primary-white
  .dropdown-menu
    padding-left: 5px
    padding-right: 5px
    table
      th
        color: $font-primary-black
        &:focus
          outline: none
          box-shadow: none
      td
        color: $font-primary-grey
        &:focus
          outline: none
          box-shadow: none
        .text-muted
          color: $font-primary-light-grey
    .btn.btn-default
      width: 100%
      color: $font-primary-black
      border-color: $primary-light-grey
      border-radius: 0
      &:hover
        background-color: $primary-light-grey
      &:focus
        outline: none
        box-shadow: none
      &:active, &.active
        outline: none
        box-shadow: none
      &.btn-info
        background-color: $primary-light-grey
        border-color: $primary-light-grey
        &.active
          color: $primary-white
          background-color: $primary-red
          border-color: $primary-red
          box-shadow: none
          .text-info
            color: $primary-white
      .text-info
        color: $primary-black
.horizontal-checkbox
  >div
    margin-right: 32px
    display: inline-block
    width: 70px

.input
  margin: 5px auto

.radio-button
  input
    display: none
  label
    margin-top: 15px
    cursor: pointer
    display: inline-block
    &:before
      content: ''
      display: inline-block
      position: relative
      top: 3px
      width: 1.1em
      height: 1.1em
      box-shadow: 0 0 0 0.10em $primary-green
      transition: 0.2s ease all
      border-radius: 50%
      margin-right: 15px
  input:checked + label:before 
    border: 0.35em solid $primary-green

.textarea-vertical-resize-only
  resize: vertical
  min-height: 5em



.switch 
  position: relative
  display: inline-block
  width: 45px
  height: 25px
  margin: 0


.switch input 
  display: none

.slider 
  position: absolute
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: #ddd
  -webkit-transition: .4s
  transition: .4s


.slider:before 
  position: absolute
  content: ""
  height: 18px
  width: 18px
  left: 4px
  bottom: 4px
  background-color: white
  -webkit-transition: .4s
  transition: .4s


input:checked + .slider 
  background-color: $primary-green


input:focus + .slider 
  box-shadow: 0 0 1px $primary-dark-grey


input:checked + .slider:before 
  -webkit-transform: translateX(18px)
  -ms-transform: translateX(18px)
  transform: translateX(18px)

/* Rounded sliders */
.slider.round
  border-radius: 34px

.slider.round:before
  border-radius: 50%

.loading-container
  .custom-loading
    margin-left: auto
    margin-right: auto
    width: 100px
    height: 100px
    border: 1px solid transparent
    border-radius: 50%
    border-top: 8px solid  $primary-green
    border-left: 6px solid $primary-green
    display: none
  &.active
    margin: 40px 0
    .custom-loading
      display: block
      animation: spin 0.7s linear infinite

.custom-input-number
  position: relative
  input[type=number]
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button 
      -webkit-appearance: none
      margin: 0
      pointer-events: none
  input[type=number] 
    -moz-appearance: textfield
.form-control
  &.error
    border-color: $primary-red!important

.custom-input-clear-btn
  position: relative
  input
    padding-right: 33px
  .button-clear
    position: absolute
    top: 8px
    right: 8px
    button
      width: 18px
      padding: 0
      border: none
      background: transparent
      line-height: 1.2
      fill: $primary-green
      text-align: left