.login-page
  width: 100vw
  display: grid
  place-items: center
  padding-top: 10vh
  @include media(xs)
    padding-top: 15px
  .login-box
    width: 400px
    max-width: 100vw
    padding: 15px