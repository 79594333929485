.line-item
  .glyphicon
    //color: black
  &.transient
    font-style: italic

.edit-price
  input
    text-align: right

.product-group, .summary-total, .total-paid
  background-color: #B5BCC9
  font-weight: bold
  input
    background-color: #B5BCC9

.payments tbody, .reservation-summary tbody
  text-align: right

.reservation-balance
  background-color: #6D7A96
  color: white
  font-weight: bold

.add-line-item
  background-color: #B5BCC9

.table.room-summary td
  white-space: nowrap
  vertical-align: middle !important

.new-line-item-date
  float: right !important

.reference-number
  margin-top: 5px
  margin-bottom: 10px

.guest-info
  div
    padding-top: 3px
    padding-bottom: 3px
  h3
    margin-top: 10px

.room-detail
  padding-top: 5px

.guest-info-table
  margin: 10px 0
  width: 100%
  max-width: 100%

.indicator-big
  font-size: 30px
  color: $primary-red

.indicator-small
  font-size: 23px
  color: $primary-red

.margin-bottom-20
  margin-bottom: 20px

.customer-dialog-body > div 
  display: flex
  margin: 10px 20px
  align-items: center
  justify-content: center
  label
    text-align: right
    margin: 0px

.payment-dialog-euro-icon
  position: absolute
  top: 59%
  left: 28px
  margin-top: -10px
  font-size: 23px

.dialog-error-field
  background-color: #f7cacc

.scrollable
  max-height: 200px
  overflow-y: auto

.nationality-dropdown
  padding-left: 5px !important
  text-align: left
  color: #555 !important

.refund-dialog-body
  margin: 10px 35px

.refund-dialog-option
  margin: 10px

.dialog-percent-icon
  position: absolute
  top: 36%
  right: 23px
  margin-top: -10px
  font-size: 23px

.total-paid-remain
  line-height: 45px
  font-size: 20px

.disable-text
  color: darkgrey

.margin-bottom-10
  margin-bottom: 10px

.text-left
  text-align: left

.refund-reason
  position: relative
  div
    display: inline
  span
    float: right
    font-size: 18px
    &:hover
      color: $primary-red

.payment-left-column
  padding: 0 15px 0 0

.payment-right-column
  padding: 0 0 0 15px

.warning-text
  color: $primary-red

.user-activity
  clear: both
  margin-bottom: 24px
  position: relative

.user-activity-title
  border-bottom: 1px solid rgba(9,45,66,.13)
  position: relative
  h2
    display: inline-block
    width: auto

.user-comment-view
  overflow: auto
  height: 180px
  border: 0.25px solid rgba(9,45,66,.13)

.user-activity-title-no-divider
  border-bottom: none

.user-activity-title-options
  float: right
  margin-top: 20px
  margin-bottom: 10px
  margin-right: 2px
  a
    line-height: 33px
    display: inline-block

.user-comment
  border-top: 1px solid rgba(9,45,66,.13)
  min-height: 32px
  padding:  12px 6px 0px 12px
  position: relative

.user-activity-action
  display: block
  margin: 0 24px 0 0
  word-wrap: break-word

.user-activity-author
  font-weight: bold

.user-activity-meta
  display: inline-block
  font-size: 12px
  margin: 0
  min-width: 110px
.comment-frame
  padding-top: 10px
  padding-left: 0
  padding-right: 0
  padding-bottom: 10px
.comment-txt-area
  float: left
  width: 83.33333334%
  overflow: hidden
  overflow-wrap: break-word
  resize: none
  border: 1px solid rgba(9,45,66,.13)

.btn-add-comment
  float: right
.quiet, .quiet a
  color: #6b808c

.format-for-btn
  margin-left: 10px

.payment-method-gift-card
  cursor: pointer