@media (min-width: 1000px)
  .voucher-container 
    width: 94%

@media (max-width: 1000px) 
  .voucher-row 
    font-size: 12px

.voucher-row:hover 
  background-color: $table-dark-grey
  cursor: pointer

@media (min-width: 992px)
  .reservation-status-label
    width: 100%

.voucher-total-count
  margin-bottom: 15px

.nopadding 
  padding: 0 !important
  margin: 0 !important

tr.selected 
  background-color: $table-light-green !important

tr.selected:hover
  background-color: $table-dark-green !important

.big-checkbox
  width: 20px
  height: 20px

.voucher-table tbody tr td
  vertical-align: middle
  &:last-child
    text-align: center

.voucher-table th
  overflow: hidden
  text-overflow: ellipsis

.voucher-detail
  tbody
    tr
      td
        vertical-align: middle
        label
          margin: 0 !important

.restriction-options
  margin: -8px 0 0 8px

tr.voucher-type
  background-color: $primary-extra-light-gray

tr.divider-row
  border-top: 5px solid $primary-light-grey

th.text-middle 
  vertical-align: middle !important

.error-glowing 
  outline: none !important
  border-color: $primary-alert !important
  box-shadow: 0 0 10px $primary-alert !important
  
.no-overflow-div
  white-space: nowrap
  width: 8em
  overflow: hidden
  text-overflow: ellipsis 
    
.allotment-menu
  max-height: 150px
  overflow: scroll
  border: 1px solid $primary-light-grey
  background-color: $primary-white
  z-index: 9999
  li 
    padding: 5px

.zindex
  z-index: 99000 !important

.exactFit
  word-wrap: break-word !important

.custom-scrollbar
  &::-webkit-scrollbar-track
    border-radius: 5px
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar:hover
    background-color: $primary-light-grey
  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: darken($primary-light-grey, 5%)

.voucher-layout
  table-layout: fixed

.name-overflow
  width: auto
  display: block
  z-index: 2
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.voucher-tooltip
  font-size: 16px

.input-width
  width: 100% !important

.input-custom-voucher
  padding: 8px 3px 8px 3px !important
  text-align: center
.padding-edit-voucher
  padding: 0 !important

.format-color-show
  color: $primary-red

.message-to-recipient-gift-card
  padding-left: 20%
  
.payments-detail-gift-card
  padding-left: 50%

.comment-gift-card
  font-size: 16px

.update-input-gift-card
  width: 158px!important

@media (min-width: 1025px)
  .detail-voucher-title
    padding: 9.96%!important
  .detail-gift-card-title
    padding: 12.96%!important

#discount-vouchers-container
  margin-top: 15px

#gift-card-container
  margin-top: 15px
