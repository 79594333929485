@media (min-width: 1000px)
  .reservation-container 
    width: 94%

@media (max-width: 1000px) 
  .reservation-row 
    font-size: 12px

.reservation-row:hover 
  background-color: $table-dark-grey
  cursor: pointer

.sort-btn
  float: right
  display: block

th:hover .sort-btn
  display: block
  cursor: pointer

.nls-less-col-padding 
   padding: 0px 5px !important
   margin: 0 !important

@media (min-width: 992px)
  .reservation-status-label
    width: 100%

.nls-input 
   padding: 5px !important
   margin: 0 !important
   font-size: 18px !important

.nls-hotel-dropdown
  min-width: 500px

.nls-btn-text-left
  text-align: left
.row-top
  margin-top: 40px
.date-selection
  margin-top: 10px
.fixmargin
  margin-top: -22px !important
.dropdown-main-text
  overflow: hidden
  font-size: 14px
  width: 140px
.no-overflow-button
  overflow: hidden
  font-size: 18px !important
.cancelled-or-unconfirmed-reservation
  color: #969696
  background: $table-light-grey
.optional-filters
  display: flex
  padding: 5px 20px
  color: black
  font-size: 15px
  cursor: pointer
  div
    padding-left: 8px
    cursor: inherit
  input
    cursor: inherit
.untouched
  cursor: not-allowed
  div
    cursor: inherit
  input
    cursor: inherit
  span
    cursor: inherit
.optional-filters:hover
  background: $primary-extra-light-gray
.reservation-total-count
  margin-bottom: 15px