.row-bottom
  margin-bottom: 40px
.mui-container
  width: 100%
  padding: 0 15px
  max-width: 1300px
  margin-left: auto
  margin-right: auto
.header-container
  background: #285557
  .bottom-header, .mui-header-container
    max-width: 1300px
    padding: 0 15px
    margin: 0 auto
  .top-header
    display: flex
    justify-content: space-between
    align-items: center
    max-width: 1300px
    padding: 0 15px
    margin: 0 auto
    padding-top: 5px
    .top-left-header
      img
        width: 150px
    .top-right-header
      color: #fff
      font-weight: 400
      font-size: 12px
      button
        margin-left: 16px

  .nav-menu
    .nav-pills>li>a
      background-color: $primary-light-grey
      display: inline-block
      padding-right: 0
      &:focus
        color: $font-primary-grey
        outline: none
        box-shadow: none
      &.new-target
        padding-left: 4px
        color: #bdbdbd

.footer-container
  position: absolute
  left: 0
  bottom: 0
  //height: 102px
  width: 100%
  padding-top: 20px
  padding-bottom: 20px
  background: $primary-light-grey
.view-back
  padding-bottom: 10px
.view-title
  font-size: $heading
  font-weight: 700
  color: $primary-green
  line-height: 1.2
  @media (max-width: 350px)
    font-size: 20pt
.view-desc
  font-size: 18pt
  font-weight: 500
.view-subtitle
  font-size: $subheading
  font-weight: bold
  
.tab-top
  padding-top: 60px

.offset-label-top
  position: relative
  top: 31px
.page-header-container
  background: $header-color
  padding: 20px 0px
  .page-header-inner
    padding: 0 15px
    max-width: 1300px
    margin: 0 auto
    .page-title-with-btn-container
      display: flex
      align-items: center
      gap: 10px
      margin-bottom: 20px
      @include media(xs)
        flex-direction: column
        align-items: normal
      .page-twb-title
        flex: 1
      .page-twb-button
        flex: 1
        text-align: right
        button
          margin-left: 15px
.main-content
  max-width: 1300px
  padding: 0 15px
  display: block
  margin: 0 auto
.container-title
  font-size: 18pt
  font-weight: 600
  padding: 10px 0
  color: $primary-green
.container-header-title
  font-size: 25pt
  font-weight: 700
  color: $primary-green
  line-height: 1.2
  .title-badge
    display: inline-flex
    align-items: center
    gap: 5px
    fill: $primary-light-green
    font-size: 12pt
    background: $primary-semi-light-green
    border: 1px solid $primary-light-green
    border-radius: 5px
    color: $primary-light-green
    padding: 5px 8px
  &.with-loading
    display: flex
    align-items: center
    gap: 15px
.container-item-header-title
  font-size: 16pt
  font-weight: 600
  color: $text-color
  line-height: 1.2
  .title-badge
    display: inline-flex
    align-items: center
    gap: 1px
    fill: $primary-light-green
    font-size: 10pt
    background: $primary-semi-light-green
    border: 1px solid $primary-light-green
    border-radius: 5px
    color: $primary-light-green
    padding: 3px 6px
    .badge-icon
      .custom-fa
        svg
          height: 16px
    &.error
      fill: $primary-red
      color: $primary-red
      background: $primary-light-red
      border: 1px solid $primary-red
  &.with-loading
    display: flex
    align-items: center
    gap: 15px
.title-inline-btn
  display: flex
  align-items: center
  gap: 15px
  margin-bottom: 15px
  .tib-title
    .container-header-title
      margin-bottom: 0
    .container-title
      padding: 0
  .tib-button
    flex-shrink: 0
  .tib-badge
    flex-shrink: 0
    .badge
      font-size: 12pt
      padding: 5px 8px
      border-radius: 5px
      color: #fff
      &.success
        background: $primary-light-green
      &.error
        background: $primary-red
.button-list
  text-align: right
  button
    margin: 7.5px 15px
    margin-right: 0
  &.no-button-top-margin
    button
      margin: 0
      margin-left: 15px
  &.label-offset
    padding-top: 37px
.bottom-filter
  display: flex
  align-items: center
  margin-top: 40px
  gap: 15px
  @include media(xs)
    align-items: flex-start
    flex-direction: column
  .bottom-filter-right
    text-align: right
    @include media(xs)
      align-self: flex-end
    button
      margin: 0
      margin-left: 15px
      @include media(xs)
        margin-bottom: 10px
    @include media(xs)
      margin-bottom: 10px
  .bottom-filter-left
    flex: 1
    button
      margin: 0
      margin-right: 10px
      @include media(xs)
        margin-bottom: 10px
.room-move-container
  display: flex
  align-items: center
  gap: 15px
  .room-list
    flex: 1
    .room-select-container
      border: 1px solid $border-color
      border-radius: 5px
  .room-action
    .room-select-arrow
      padding: 10px 0
.inline-edit-container
  display: inline-flex
  align-items: center
  gap: 5px
  font-size: 14pt
  .action-button
    display: inline-flex
    align-items: center
    gap: 5px
.feature-container
  margin-bottom: 30px
  background: #fff
  box-shadow: 0px 0px 10px 0px rgba(209,209,209,1)
  border: 1px solid #c4c4c4
  border-radius: 10px
  padding: 15px
  .feature-title
    border-bottom: 1px solid #c4c4c4
    padding-bottom: 20px
    margin-bottom: 20px
    &.have-label-under
      margin-bottom: 10px
    h3
      font-weight: 700
      color: #285557
      margin-top: 0
    .title-with-logo
      display: flex
      align-items: center
      gap: 10px
      margin-bottom: 10px
      flex-wrap: wrap
      .logo
        img
          width: auto
          height: 20px
        &.with-bg
          background: $primary-green
          border-radius: 5px
          padding: 5px
          img
            height: 15px
      .title
        h3
          margin-bottom: 0
      .feature-status
        background: $primary-green
        padding: 5px 10px
        font-weight: 600
        text-transform: uppercase
        border-radius: 5px
        color: #fff
        font-size: 10pt
        &.wip
          background: $primary-orange
    &.modal-interaction
      display: flex
      align-items: flex-start
      border: none
      margin: 0
      padding: 0
      .content-text
        flex: 1
