.customer-status
  margin-top: -15px

.format-input-customer
  margin-top: 16px

.input-state-reason-customer
  padding-top: 10px

.btn-group > .btn-customer:first-child
  margin-left: -5px
  width: 101%

.btn-add-customer
  margin-right: 15px

.format-customer
  margin: 0px 2px

.radio-unblocked
  padding-top: 5px

.state-selected
  background-color: #EEEEEE

.has-not-choose-option-filter
  background-color: $primary-bg-alert
  color: $primary-alert
  border-color: $primary-alert !important

.dropdown-menu > li > a.state-customer
  text-align: center

.open > .state-customer-dropdown-menu
  width: 100%

.customer-row:hover 
  background-color: $table-dark-grey
  cursor: pointer

.bootstrap-select > .dropdown-toggle
  background-color: $primary-white
  border: 1px solid $primary-dark-grey

.format-btn
  margin-bottom: 12px

// Format customer - card page
.table-customer-card

  tr > td
    border-top-style: none!important
  tr > td
    padding-left: 15px!important
  tbody
    td
      border-right: 1px solid #ddd
  td + td 
    border-right: none


.detail-comments
  padding: 0px 0px 0px 0px

.comment-format
  font-size: 14px
  line-height: 24px
  color: #424F68
  cursor: pointer
  font-weight: 400

.past-booking
  font-size: 13px
  cursor: pointer

.btn-orange
  background-color: $primary-orange
  color: $primary-white
  width: 125%

.blocket-btn
  margin-top: -20%

.arrow-btn
  margin-top: -1%
  font-size: 12px
  margin-left: 1%
  &:hover
    cursor: pointer

.see-more-comment
  padding-bottom: 1.5%
  font-weight: 600
  font-size: 14px
  line-height: 24px
  color: #424F68

.customer-card-title
  padding-left: 1.12%

.truncate-long-text
  overflow: hidden
  text-overflow: ellipsis

// Dashboard on customers
.dashboard
  padding-top: 1.5%

.customer-dashboard-title
  padding-left: 20px

.customer-title
  padding-left: 20px

.box
  text-align: center
  display: flex
  flex-flow: row wrap
  justify-content: space-around
  padding-top: 10px

  > .item-box
    border-radius: 6px
    background-color: #fff
    padding: 30px 53px 20px 53px
    position: relative

    > h5
      font-weight: bolder

    > div
      padding-top: 8px
      font-size: 28px
      font-weight: bolder
      margin-bottom: -7px

      > span
        position: absolute
        font-size: 24px
        margin-top: -10px  
        > small
          font-size: 11px

.upward-arrow
  color: $upward-arrow-color
  margin: -5px

.downward-arrow
  color: $downward-arrow-color
  margin: -5px

span.upward-arrow-icon
  &::after
    content: "\2191"

span.downward-arrow-icon
  &::after
    content: "\2193"

.note-text
    font-size: 10.5px

.dropdown-item-checked-icon
  font-weight: bolder
  color: $primary-red
  margin-right: 1rem

.compare-to-options
  > div
    width: 20px

@media (min-width: 1199px)
  .box > .item-box
    width: 30%
    margin: 0rem 0rem 0rem 1.6rem

    > div > span
      margin-left: 0.2rem

  .nls-less-col-padding-btn
    padding: 2.6rem 10px !important
  
  .comment-width
    width: 330px

  .options
    margin-left: -4.8rem

  .note-text
    padding: 0px
    margin: 0rem 0rem 0rem 1.4rem
    font-size: 10.5px
  
  .message-alert-dashboard
    margin-left: 1.5rem

@media (min-width: 700px) and (max-width: 1199px)
  .box > .item-box
    width: 170px
    margin: 2rem 0rem 0rem 1.6rem

    > div > span
      margin-left: 0.2rem

  .nls-less-col-padding-btn
    padding: 2.6rem 10px !important
  
  .comment-width
    width: 330px

  .options
    margin-left: -3rem

  .note-text
    position: absolute
    left: 14px
    width: 450px
    top: 100%
    font-size: 10.5px
  
  .message-alert-dashboard
    margin-left: 1.5rem

@media (min-width: 768px) and (max-width: 700px)
  .box > .item-box
    width: 165px
    margin: 2rem 1.8rem 0rem 0rem

    > div > span
      margin-left: 0.5rem
  
  .nls-less-col-padding-compare-to
    padding: 0px 10px !important
    margin: 0 !important

  .nls-less-col-padding-btn
    padding: 10px 20px !important
    margin: 0 !important

  .comment-width
    width: 230px

@media (min-width: 400px) and (max-width: 767px)
  .box > .item-box
    width: 200px
    margin: 2rem 1.8rem 0rem 0rem

    > div > span
      margin-left: 1rem

  .nls-less-col-padding-compare-to
    padding: 0px 10px !important
    margin: 0 !important

  .nls-less-col-padding-btn
    padding: 10px 20px !important
    margin: 0 !important

  .comment-width
      width: 200px

@media (max-width: 399px)
  .item-box
    width: 80%
    margin: 1rem 2rem 1rem 2rem
    > div > span
      margin-left: 2rem

  .nls-less-col-padding-compare-to
    padding: 0px 10px !important
    margin: 0 !important

  .nls-less-col-padding-btn
    padding: 10px 20px !important
    margin: 0 !important
  
  .comment-width
    width: 150px

.customer-card-comment-view
  border: 1px solid #EBE7DF
  border-radius: 6px
  padding: 16px 17px 13px 16px
  background-color: #fff
  width: 100%
  height: 400px
  overflow-y: auto

.booking-window
  padding-left: 15px

.customer-data-export-title
  padding-left: 20px

.customer-dashboard-tooltip
  width: 290px
  white-space: pre-wrap
  
  > div
    margin-top: 10px
    text-align: left

.attr-title
  padding-bottom: 5px