.nls-tooltip-text
	color: $primary-dark-grey

.nls-room-box
	background: $primary-light-grey
	padding: 30px
	border-radius: 20px

.nls-guest-box
	background: $primary-white
	padding: 30px
	border-radius: 20px

.btn-red:active, .btn-red.active
	background: $primary-dark-red
