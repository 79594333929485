.double-list
  width: 100%
  margin-top: 20px
  margin-bottom: 20px
  .first-list
    padding: 0
  .second-list
    padding: 0
  .controls
    line-height: 300px
    height: 300px
    text-align: center
    position: relative
    top: 27px
    .buttons
      width: 50%
      display: inline-block
      vertical-align: middle
      .btn
        display: block
        margin-top: 5px
  .list
    border: 1px solid $primary-dark-grey
    border-radius: 2px
    height: 300px
    overflow-y: scroll
    &.custom-scrollbar
      &::-webkit-scrollbar-track
        border-radius: 5px
      &::-webkit-scrollbar
        width: 4px
      &::-webkit-scrollbar:hover
        background-color: $primary-light-grey
      &::-webkit-scrollbar-thumb
        border-radius: 5px
        background-color: darken($primary-light-grey, 5%)
  .list-item
    padding: 5px 15px
    width: 100%
    position: relative
    cursor: pointer
    &:hover
      background-color: $table-light-grey
    &.selected
      background-color: $table-dark-grey

.mark-done-checkbox
  position: absolute
  right: 34px
  top: -8px