.date-container-ecilco
  width: 100%
  display: flex
  .info-column
    border: 1px solid $primary-dark-grey 
    height: 94px
    width: 160px !important
    min-width: 160px !important
    .title-container
      flex: 0 0 auto
      width: 100%
      .title
        height: 52px
        width: 100%
        margin: 0
        @include flex
        & > *
          text-align: center
          vertical-align: middle
          font-weight: bold
          margin: 0
    .info-block
      border-top: 1px solid $primary-dark-grey
      font-weight: bold
      width: 100%
      height: 40px
      text-align: center
      vertical-align: middle
      margin: 0
      line-height: 40px
  .ecilco-column
    border-top: 1px solid $primary-dark-grey 
    border-right: 1px solid $primary-dark-grey 
    border-bottom: 1px solid $primary-dark-grey 
    width: 70px
    min-width: 70px
    .ecilco-date
      box-sizing: border-box
      flex: 0 0 auto
      width: 100%
      .date 
        padding-top: 4px
        height: 52px
        width: 100%
        margin: 0
        font-weight: bold
        p
          text-align: center
          margin: 0
    .total
      position: relative
      width: 100%
      height: 40px
      text-align: center
      vertical-align: middle
      margin: 0
      line-height: 40px
      border-top: 1px solid $primary-dark-grey
    &.weekend
      background-color: #ddd

.date-container-eci
  width: 100%
  display: flex
  .title-column
    border: 1px solid $primary-dark-grey 
    height: 161px
    width: 70px
    min-width: 70px
    font-weight: bold
    color: $primary-white
    background-color: $primary-blue
    text-align: center
  .info-column
    border-top: 1px solid $primary-dark-grey
    border-right: 1px solid $primary-dark-grey
    height: 161px
    width: 90px
    min-width: 90px
    .info-block
      border-bottom: 1px solid $primary-dark-grey
      font-weight: bold
      width: 100%
      height: 40px
      text-align: center
      vertical-align: middle
      margin: 0
      line-height: 40px
  .eci-column
    border-top: 1px solid $primary-dark-grey
    border-right: 1px solid $primary-dark-grey
    width: 70px
    min-width: 70px
    .buffer-input
      position: relative
      width: 100%
      height: 40px
      border-bottom: 1px solid $primary-dark-grey
      @include flex
      input
        border: 1px solid $primary-grape
        width: 90%
        border-radius: 5px
        height: 28px
        text-align: center
        color: $primary-black
        -moz-appearance: textfield
        -webkit-box-shadow: inset 0 0 10px $primary-light-grape
        -moz-box-shadow: inset 0 0 10px $primary-light-grape
        box-shadow: inset 0 0 10px $primary-light-grape
        &.modified-by-system
          -moz-box-shadow: inset 0 0 20px #90ee02
          box-shadow: inset 0 0 20px #90ee02
          border: 1px solid #09af00
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button
          -webkit-appearance: none
    .used, .left
      position: relative
      width: 100%
      height: 40px
      text-align: center
      vertical-align: middle
      margin: 0
      line-height: 40px
      border-bottom: 1px solid $primary-dark-grey
    .close-to-day
      position: relative
      width: 100%
      height: 40px
      text-align: center
      vertical-align: middle
      margin: 0
      line-height: 40px
      border-bottom: 1px solid $primary-dark-grey
      padding-top: 5px
      &:hover
        box-shadow: inset 0 0 30px $primary-light-red
        cursor: pointer
      .toggle-icon
        font-size: 24px
        color: $primary-red
    &.weekend
      background-color: #ddd

.date-container-lco
  width: 100%
  display: flex
  .title-column
    border: 1px solid $primary-dark-grey 
    height: 161px
    width: 70px
    min-width: 70px
    font-weight: bold
    color: $primary-white
    background-color: $primary-red
    text-align: center
  .info-column
    border-top: 1px solid $primary-dark-grey
    border-right: 1px solid $primary-dark-grey
    height: 161px
    width: 90px
    min-width: 90px
    .info-block
      border-bottom: 1px solid $primary-dark-grey
      font-weight: bold
      width: 100%
      height: 40px
      text-align: center
      vertical-align: middle
      margin: 0
      line-height: 40px
  .lco-column
    border-top: 1px solid $primary-dark-grey
    border-right: 1px solid $primary-dark-grey
    width: 70px
    min-width: 70px
    .buffer-input
      position: relative
      width: 100%
      height: 40px
      border-bottom: 1px solid $primary-dark-grey
      @include flex
      input
        border: 1px solid $primary-grape
        width: 90%
        border-radius: 5px
        height: 28px
        text-align: center
        color: $primary-black
        -moz-appearance: textfield
        -webkit-box-shadow: inset 0 0 10px $primary-light-grape
        -moz-box-shadow: inset 0 0 10px $primary-light-grape
        box-shadow: inset 0 0 10px $primary-light-grape
        &.modified-by-system
          -moz-box-shadow: inset 0 0 20px #90ee02
          box-shadow: inset 0 0 20px #90ee02
          border: 1px solid #09af00
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button
          -webkit-appearance: none
    .used, .left
      position: relative
      width: 100%
      height: 40px
      text-align: center
      vertical-align: middle
      margin: 0
      line-height: 40px
      border-bottom: 1px solid $primary-dark-grey
    .close-to-day
      position: relative
      width: 100%
      height: 40px
      text-align: center
      vertical-align: middle
      margin: 0
      line-height: 40px
      border-bottom: 1px solid $primary-dark-grey
      padding-top: 5px
      &:hover
        box-shadow: inset 0 0 30px $primary-light-red
        cursor: pointer
      .toggle-icon
        font-size: 24px
        color: $primary-red
    &.weekend
      background-color: #ddd

.detail-container-ecilco
  display: inline-flex
  flex-wrap: nowrap
  overflow-y: hidden
  overflow-x: scroll
  scroll-behavior: smooth
  direction: ltr
  &::-webkit-scrollbar-track
    border-radius: 5px
  &::-webkit-scrollbar
    width: 4px
    height: 10px
  &::-webkit-scrollbar:hover
    background-color: $primary-light-grey
    border-radius: 5px
  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: darken($primary-light-grey, 15%)

.detail-container-eci
  display: inline-flex
  flex-wrap: nowrap
  overflow-y: hidden
  overflow-x: scroll
  scroll-behavior: smooth
  direction: ltr
  &::-webkit-scrollbar-track
    border-radius: 5px
  &::-webkit-scrollbar
    width: 4px
    height: 10px
  &::-webkit-scrollbar:hover
    background-color: $primary-light-grey
    border-radius: 5px
  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: darken($primary-light-grey, 15%)

.detail-container-lco
  display: inline-flex
  flex-wrap: nowrap
  overflow-y: hidden
  overflow-x: scroll
  scroll-behavior: smooth
  direction: ltr
  &::-webkit-scrollbar-track
    border-radius: 5px
  &::-webkit-scrollbar
    width: 4px
    height: 10px
  &::-webkit-scrollbar:hover
    background-color: $primary-light-grey
    border-radius: 5px
  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: darken($primary-light-grey, 15%)

.ecilco-dummy-flash-grape
  position: absolute
  width: 70px
  height: 40px
  display: inline-block
  z-index: 3
  top: 0
  left: 0
  -webkit-animation-name: k-flash-grape
  -webkit-animation-duration: 0.3s
  -moz-animation-name: k-flash-grape
  -moz-animation-duration: 0.3s
  -o-animation-name: k-flash-grape
  -o-animation-duration: 0.3s
  -ms-animation-name: k-flash-grape
  -ms-animation-duration: 0.3s
  animation-name: k-flash-grape
  animation-duration: 0.3s
