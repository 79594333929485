#hotel-usage
  padding: 0px
  .vacant
    background-color: transparent
  .initial-allocation
    background-color: #6D7A96
  .room-not-available
    background-color: black
    opacity: 0.5
  .permanent-allocation
    background-color: #D4212A
  .maintenace-allocation
    background-color: #FBC02D
  .allotment-allocation
    background-color: #C66639
    z-index: 1
  .row
    margin-left: 0px
    margin-right: 0px

.usage-date-header
  position: absolute
  background-color: transparent
  border-left: 1px solid darkgray
  height: 100%

.usage-past
  position: absolute
  top: 40px
  height: 100%
  background-color: lightgray

.usage-room-pending
  background-color: #409EFF!important

.usage-mismatch-capacity
  background-color: #800080!important

.last-date-header
  border-right: 1px solid lightgray

.usage-date-header-label
  transform: rotate(-45deg)
  transform-origin: top left
  white-space: nowrap

#rooms
  margin-top: 20px

.room
  margin: 4px 0px 4px 0px
  padding: 2px

.room-usage
  text-align: right
  padding-top: 4px
  padding-right: 4px
  overflow: hidden
  border-radius: 5px
  z-index: 2
  position: relative
  a
    position: relative
    z-index: 5
    color: white

.selected-reservation
  position: relative
  &::after
    content: ""
    position: absolute
    width: 100%
    height: 100%
    display: block
    z-index: 3
    top: 0
    left: 0
    -webkit-background-size: 40px 40px
    background-size: 40px 40px
    background-image: -webkit-linear-gradient(135deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)
    background-image: -o-linear-gradient(135deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)
    background-image: linear-gradient(135deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)


.selected-reservation.selected-room-request
  position: relative
  &::after
    content: "."
    position: absolute
    width: 100%
    height: 100%
    display: block
    z-index: 3
    top: 0
    left: 0
    background-color: black
    -moz-opacity: 0.5
    -khtml-opacity: 0.5
    opacity: 0.5

// background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
// background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
// background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);

.dragging
  opacity: 0.5

.drag-over
  background-color: pink

.room-label
  font-weight: bold
  vertical-align: middle
  color: white
  background: $font-primary-grey
  opacity: 0.74
  padding: 3px 8px
  border-radius: 25px
  cursor: default
  margin-right: 1px
  &:hover
    opacity: 1

.popover
  max-width: 100%!important
  z-index: 3

#failedReAllocation
  z-index: 4

.room-capacity-selected
  background-color: #8A8E8E

.room-info
  text-align: center

.room-info-body
  text-align: center
  margin-left: 10px

.format-thead-table
  text-align: center

.table td.truncate-body-room-info span
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  display: block
  max-width: 100%

.text-truncate
  width: 250px
  white-space: pre
  overflow: hidden
  text-overflow: ellipsis

.title-history-log
  padding-left: 10px

.ibtn-room-info
  height: 43px
  width: 43px

.ibtn-room-info-cancel
  margin-left: 5px

.my-custom-scrollbar
  position: relative
  height: 150px
  overflow: auto

.table-wrapper-scroll-y
  display: block
  
.room-for-disabled
  background: $primary-red
  opacity: 0.74

.room-capacity
  background: #FF7800
  opacity: 0.74
  padding: 3px 7px

.noselect
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.usage-rows
  position: relative
  top: 18px

.usage-row
  display: block
  position: relative
  height: 30px
  &:nth-child(even)
    background-color: #d5d5d5
  &:nth-child(odd)
    background-color: #eee
.ar-label
  width: 120px
  padding: 5px
  position: absolute
  cursor: pointer
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
.ar-amount-per-day
  padding: 5px
  position: absolute
  text-align: center
  border-left: 1px solid #888
  &:last-child
    border-right: 1px solid #888

.backdrop
  position: absolute
  width: 100%
  background-color: rgba($primary-white, 1)
  height: 60px
  top: -40px

.allotment-toggle
  position: absolute
  left: -50px
  top: 133px
  width: 105px
  height: 36px
  padding: 5px

.separator
  border-top: 1px solid $primary-dark-grey
  width: 100%
  height: 1px
  &.active
    border-top: 1px solid $primary-dark-red

.zero-amount
  color: $primary-red
  background: #FFEEEE

.tooltip-inner
  max-width: 400px

// Show and hide legend
.table > tbody > tr > td.table-legend
  border-top: 0px!important

.btn-legend
  height: 13px
  width: 25px
  border-radius: 5px

.btn-legend-reservation
  background-color: #6E7B95
  border: 1px solid #6E7B95

.btn-legend-pending-reservation
  background-color: #46A0FC
  border: 1px solid #46A0FC

.btn-legend-manually-allocated
  background-color: #D22431
  border: 1px solid #D22431

.btn-legend-mainternance
  background-color: #FBC02D
  border: 1px solid #FBC02D

.btn-legend-hard-allotment
  background-color: #C66639
  border: 1px solid #C66639

.btn-legend-eci-late
  background-color: #37C120
  border: 1px solid #37C120

.show-legend
  padding-top: 22px

.show-legend:hover, .hiden-legend:hover
  cursor: pointer

.all-channels
  margin-left: -15px

.btn-legend-reservation
  background-color: #6E7B95
  border: 1px solid #6E7B95

.btn-legend-pending-reservation
  background-color: #46A0FC
  border: 1px solid #46A0FC

.btn-legend-manually-allocated
  background-color: #D22431
  border: 1px solid #D22431

.btn-legend-maintenance
  background-color: #FBC02D
  border: 1px solid #FBC02D

.btn-legend-hard-allotment
  background-color: #C66639
  border: 1px solid #C66639

.btn-legend-eci-late
  background-color: #37C120
  border: 1px solid #37C120

.btn-legend-show-legend
  float: right
  font-size: 13px
  border: none
  outline: none
  margin-top: -4rem

  &:focus
    border: none
    outline: none

.btn--legendshow-legend:hover
  border: none
  outline: none

@media (min-width: 1024px)
  .legend-container
    display: flex
    height: 60px
    flex-flow: column wrap

    > div
      text-align: left
      font-size: 14px
      flex-flow: column wrap

      &.reservation-control, &.maintenance-control
        width: 110px
        margin-left: 2rem

      &.pending-reservation-control, &.hard-allotment-control
        width: 130px
        margin-left: -5rem

      &.manually-allocated-control, &.eci-lio-control
        width: 160px
        margin-left: -5rem

  .btn-legend
    height: 13px
    width: 25px
    border-radius: 5px

  .btn-legend-show-legend
    margin-right: -30px

  .legend-container > div > label
    font-size: 13px
    font-weight: normal

@media (min-width: 768px) and (max-width: 1023px)
  .legend-container
    display: flex
    height: 60px
    flex-flow: column wrap

    > div
      text-align: left
      font-size: 14px
      flex-flow: column wrap

      &.reservation-control, &.maintenance-control
        width: 110px

      &.pending-reservation-control, &.hard-allotment-control, &.manually-allocated-control, &.eci-lio-control
        width: 130px
        margin-left: -5rem

  .btn-legend
    height: 13px
    width: 25px
    border-radius: 5px

  .btn-legend-show-legend
    margin-right: -15px

  .legend-container > div > label
    font-size: 13px
    font-weight: normal

@media (min-width: 550px) and (max-width: 768px)
  .legend-container
    display: flex
    height: 60px
    flex-flow: column wrap

    > div
      text-align: left
      font-size: 14px
      flex-flow: column wrap

      &.reservation-control, &.maintenance-control
        width: 110px
        margin-left: -1.8rem

      &.pending-reservation-control, &.hard-allotment-control, &.manually-allocated-control, &.eci-lio-control
        width: 130px

      > button
        border-radius: 5px
  .btn-legend
    height: 13px
    width: 25px
    border-radius: 5px

  .legend-container > div > label
    font-size: 12px
    font-weight: normal

@media (min-width: 439px) and (max-width: 550px)
  .legend-container
    display: flex
    height: 60px
    flex-flow: column wrap

    > div
      text-align: left
      font-size: 14px
      flex-flow: column wrap

      &.reservation-control, &.maintenance-control
        width: 110px
        margin-left: -2rem

      &.pending-reservation-control, &.hard-allotment-control
        width: 120px

      &.manually-allocated-control, &.eci-lio-control
        width: 160px
        margin-left: -1rem

      > button
        border-radius: 5px
  .btn-legend-show-legend
    margin-top: -10px

  .btn-legend
    height: 13px
    width: 25px
    border-radius: 5px

  .legend-container > div > label
    font-size: 11px
    font-weight: normal

@media (min-width: 321px) and (max-width: 439px)
  .legend-container
    display: flex
    height: 60px
    flex-flow: column wrap

    > div
      text-align: left
      font-size: 14px
      flex-flow: column wrap

      &.reservation-control, &.maintenance-control
        width: 110px
        margin-left: -2rem

      &.pending-reservation-control, &.hard-allotment-control
        width: 130px

      &.manually-allocated-control, &.eci-lio-control
        width: 160px
        margin-left: -1rem

      > button
        border-radius: 5px

  .btn-legend-show-legend
    margin-top: -10px

  .btn-legend
    height: 13px
    width: 25px
    border-radius: 5px

  .legend-container > div > label
    font-size: 11px
    font-weight: normal

@media (max-width: 320px)
  .legend-container
    display: flex
    flex-flow: column

  .btn-legend
    height: 10px
    width: 20px
    border-radius: 5px

  .legend-container > div > label
    font-size: 11px
    font-weight: normal
  
  .btn-legend-show-legend
    float: right
    font-size: 12px
    margin-top: 2rem
.display-none
  display: none