.date-container-channel
  width: 100%
  display: flex
  flex-wrap: nowrap
  overflow-y: hidden
  overflow-x: scroll
  // &::-webkit-scrollbar 
  //   display: none
  // 
  direction: ltr
  // -webkit-overflow-scrolling: touch
  scroll-behavior: smooth
  .info-column
    border-left: 1px solid $primary-dark-grey 
    width: 130px !important

  .channel-date 
    flex: 0 0 auto
    width: 70px
    border-right: 1px solid $primary-dark-grey 
    border-top: 1px solid $primary-dark-grey 
    border-bottom: 1px solid $primary-dark-grey
    .date 
      padding-top: 4px
      height: 52px
      text-align: center
      width: 100%
      margin: 0
      border-bottom: 1px solid $primary-dark-grey
      font-weight: bold
      p
        margin: 0
    .title
      height: 52px
      width: 100%
      margin: 0
      border-bottom: 1px solid $primary-dark-grey
      background-color: $primary-red
      @include flex
      & > *
        text-align: center
        vertical-align: middle
        color: $primary-white
        font-weight: bold
        margin: 0
    .buffer-input
      width: 100%
      height: 40px
      @include flex
      &.sold-out
        -webkit-box-shadow: inset 0 0 20px $primary-pink
        -moz-box-shadow: inset 0 0 20px $primary-pink
        box-shadow: inset 0 0 20px $primary-pink
      input
        border: 1px solid $primary-grape
        width: 90%
        border-radius: 5px
        height: 28px
        text-align: center
        color: $primary-black
        -moz-appearance: textfield
        -webkit-box-shadow: inset 0 0 10px $primary-light-grape
        -moz-box-shadow: inset 0 0 10px $primary-light-grape
        box-shadow: inset 0 0 10px $primary-light-grape
        &.modified-by-system
          -webkit-box-shadow: inset 0 0 20px #90ee02
          -moz-box-shadow: inset 0 0 20px #90ee02
          box-shadow: inset 0 0 20px #90ee02
          border: 1px solid #09af00
        &.invalid-amount
          background-color: $primary-light-red
          color: $primary-red
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button
          -webkit-appearance: none
    .used, .left
      position: relative
      border-top: 1px solid $primary-dark-grey
      width: 100%
      height: 40px
      text-align: center
      vertical-align: middle
      margin: 0
      line-height: 40px
      &.invalid-amount
        background-color: $primary-light-red
        color: $primary-red
    .buffer
      position: relative
      width: 100%
      height: 40px
      text-align: center
      vertical-align: middle
      margin: 0
      line-height: 40px
      &.invalid-amount
        background-color: $primary-light-red
        color: $primary-red
    &.weekend
      background-color: #ddd
  &::-webkit-scrollbar-track
    border-radius: 5px
  &::-webkit-scrollbar
    width: 4px
    height: 10px
  &::-webkit-scrollbar:hover
    background-color: $primary-light-grey
    border-radius: 5px
  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: darken($primary-light-grey, 15%)

.modal-xlg
  width: 80%

.channel-dummy-flash-grape
  position: absolute
  width: 70px
  height: 40px
  display: inline-block
  z-index: 3
  top: 0
  left: 0
  -webkit-animation-name: k-flash-grape
  -webkit-animation-duration: 0.3s
  -moz-animation-name: k-flash-grape
  -moz-animation-duration: 0.3s
  -o-animation-name: k-flash-grape
  -o-animation-duration: 0.3s
  -ms-animation-name: k-flash-grape
  -ms-animation-duration: 0.3s
  animation-name: k-flash-grape
  animation-duration: 0.3s

.flash-grape
  -webkit-animation-name: k-flash-grape
  -webkit-animation-duration: 0.3s
  -moz-animation-name: k-flash-grape
  -moz-animation-duration: 0.3s
  -o-animation-name: k-flash-grape
  -o-animation-duration: 0.3s
  -ms-animation-name: k-flash-grape
  -ms-animation-duration: 0.3s
  animation-name: k-flash-grape
  animation-duration: 0.3s

@keyframes k-flash-grape 
  50%
    background-color: $primary-grape

@-moz-keyframes k-flash-grape 
  50%
    background-color: $primary-grape

@-webkit-keyframes k-flash-grape 
  50%
    background-color: $primary-grape

@-o-keyframes k-flash-grape 
  50%
    background-color: $primary-grape

@-ms-keyframes k-flash-grape 
  50%
    background-color: $primary-grape
