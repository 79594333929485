.theme-nelson
  .bs-datepicker-container
    padding: 0
  .bs-datepicker-head
    background-color: #fff
    border-bottom: 1px solid $border-color
    @include shadow-down
    button
      color: $primary-green
      font-size: 16pt
      padding: 0 10px
      font-weight: 600
      &:disabled
        opacity: 0
        pointer-events: none
  .bs-datepicker-body
    min-width: 335px
    border: none
    table
      border-collapse: collapse
      thead
        th
          color: $primary-green
          font-size: 12pt
          font-weight: 600
      tbody
        td
          span
            border-radius: 5px
            height: 35px
            font-size: 14pt
            box-sizing: border-box
            border: none
            padding: 0
            &.selected
              background-color: $text-color
            &.datepicker-today
              color: $primary-green
              font-weight: 600
              border: 2px solid $primary-green
              line-height: 1.6!important
            &.is-highlighted
              background-color: $menu-hover-color!important
              color: $text-color
              &.selected
                background-color: $text-color-light!important
                color: #fff
              &.datepicker-today.selected
                background-color: $primary-green-light!important
                color: #fff
            &.dateRange
              background-color: $menu-hover-color
              border-radius: 0!important
              &.is-other-month
                background-color: $menu-hover-color-light
              &.is-highlighted
                background-color: $menu-hover-color-light!important
            &.selectedDate
              background-color: $text-color
              color: #fff
              &.datepicker-today
                background-color: $primary-green
                border: none
                color: #fff
                line-height: 1.8!important
              &.is-other-month
                background-color: $primary-green-light
                color: #b4b4b4
              &.start-alone, &.end-alone
                border-radius: 5px!important
              &.start
                border-radius: 5px 0 0 5px!important
              &.end
                border-radius: 0 5px 5px 0!important
              &.is-highlighted
                background-color: $primary-green-light!important
          &.is-highlighted
            span
              background-color: $menu-hover-color!important
              color: $text-color
              &.selected
                background-color: $text-color-light!important
                color: #fff
      &.years
        tbody
          td
            height: 59px
            span
              width: 73.75px
              height: 35px
              line-height: 1.8
              margin-bottom: 0
      &.months
        tbody
          td
            height: 59px
            span
              font-size: 13pt
              width: 100px
              height: 35px
              padding: 0
              line-height: 1.9
              margin-bottom: 0
      &.days
        thead
          th
            min-width: 45px
        tbody
          td
            span
              width: 100%
              line-height: 1.8