.hotel-table
  padding-bottom: 10px
table
  border-collapse: collapse
  border-spacing: 0

.format-force-start-date
  padding-left: 55px !important

.format-force-end-date
  padding-left: 23px !important
.format-none-start-date-or-end-date
  padding-left: 60px !important
.format-force-none-start-date
  padding-left: 95px !important

.hotel-table
  td
    border: solid 1px #ddd
    border-style: none
    padding: 10px 30px 10px 21px
  td + td
    border-left: 0px solid #ddd
  tr:first-child td:first-child
    border-top-left-radius: 10px
    border-top-style: solid !important
  tr:first-child td:last-child
    border-top-right-radius: 10px
  tr:last-child td:first-child
    border-bottom-left-radius: 10px
    border-bottom-style: solid !important
  tr:last-child td:last-child
    border-bottom-right-radius: 10px
    border-bottom-style: solid !important
  tr td:first-child
    border-right-style: none !important
  tr td:first-child
    border-bottom-style: none !important
  tr td:last-child
    border-bottom-style: none !important
    border-right-style: solid !important
  tr:first-child td
    border-top-style: solid
  tr:last-child td
    border-bottom-style: solid
  tr td:first-child
    border-left-style: solid

.save-btn
  margin-top: 20px
  margin-right: 1.5px

.edit-btn-hotels
  padding: 0px
  margin-bottom: 3%
.w-20
  width: 20%

@media (min-width: 1025px)
  .hotel-label
    margin-left: -3.5%
  .start-date-label
    margin-left: -8.3%
  .end-date-label
    margin-left: -5.9%
  .is-enabled
    margin-left: -3.5%
  .edit-format
    margin-left: 31.1%
    width: 14.3%
  .is-enabled-body
    margin-left: 10%
  .edit-btn
    padding: 3px 10px
    width: 61.5% !important
  .format-start-date-end-date-null
    padding-left: 97px !important
  .format-is-enabled
    padding-left: 85px !important

@media (min-width: 1023px) and (max-width: 1024px)
  .hotel-label
    margin-left: -2.5%
  .start-date-label
    margin-left: -6.5%
  .end-date-label
    margin-left: -3.5%
  .is-enabled
    margin-left: -1%
  .edit-format
    margin-left: 37%
  .edit-btn
    width: 72% !important
  .format-start-date-end-date-null
    padding-left: 97px !important
  .format-is-enabled
    padding-left: 85px !important

@media (min-width: 768px) and (max-width: 1023px)
  .hotel-label
    padding-left: 1%
  .start-date-label
    margin-left: 3%
  .end-date-label
    padding-left: 12.5%
  .is-enabled
    margin-left: 16%
  .edit-btn
    width: 68.5% !important
  .edit-format
    margin-left: 65%
  .format-start-date-end-date-null
    padding-left: 97px !important
  .format-is-enabled
    padding-left: 85px !important

@media (min-width: 550px) and (max-width: 768px)
  .hotel-label
    padding-left: 1%
  .start-date-label
    margin-left: 2%
  .end-date-label
    padding-left: 12.5%
  .is-enabled
    margin-left: 15%
  .edit-btn
    width: 65% !important
  .edit-format
    margin-left: 64%
  .hotel-table
    td
      padding: 10px 70px 10px 21px
  .format-start-date-end-date-null
    padding-left: 93px !important
  .format-is-enabled
    padding-left: 82px !important

@media (min-width: 415px) and (max-width: 550px)
  .hotel-label
    padding-left: 7%
  .start-date-label
    padding-left: 11%
  .end-date-label
    padding-left: 20%
  .is-enabled
    margin-left: 21%
  .edit-btn
    width: 97% !important
  .hotel-table
    td
      padding: 10px 36px 10px 21px
  .format-start-date-end-date-null
    padding-left: 93px !important
  .format-is-enabled
    padding-left: 82px !important

@media (min-width: 376px) and (max-width: 414px)
  .hotel-label
    padding-left: 7.5%
  .start-date-label
    padding-left: 9%
  .end-date-label
    padding-left: 19%
  .is-enabled
    margin-left: 14%
  .edit-btn
    width: 92% !important
  .format-force-none-start-date
    padding-left: 92px !important
  .hotel-table
    td
      padding: 10px 10px 10px 14px
  .format-start-date-end-date-null
    padding-left: 85px !important
  .format-is-enabled
    padding-left: 65px !important
    padding-right: 25px !important

@media (min-width: 321px) and (max-width: 375px)
  .hotel-label
    padding-left: 7.5%
  .start-date-label
    padding-left: 8%
  .end-date-label
    padding-left: 17%
  .is-enabled
    margin-left: 12%
  .edit-btn
    width: 87% !important
  .format-force-none-start-date
    padding-left: 80px !important
  .hotel-table
    td
      padding: 10px 0px 10px 10px
  .format-start-date-end-date-null
    padding-left: 80px !important
  .format-is-enabled
    padding-left: 60px !important
    padding-right: 25px !important

@media (max-width: 320px)
  .hotel-label
    padding-left: 7%
  .start-date-label
    padding-left: 6%
  .end-date-label
    padding-left: 17%
  .is-enabled
    margin-left: 9%
  .edit-btn
    width: 92% !important
  
  .format-force-start-date
    td
      padding: 10px 2px 10px 0px
  .hotel-table
    td
      padding: 10px 2px 10px 0px
  .format-start-date-end-date-null
    padding-left: 75px !important
  .format-is-enabled
    padding-left: 45px !important
    padding-right: 27px !important

@media (max-width: 280px)
  .hotel-label
    padding-left: 6%
  .start-date-label
    padding-left: 9%
  .end-date-label
    padding-left: 23%
  .is-enabled
    margin-left: 8%
  .edit-btn
    width: 107% !important
  .hotel-table
    td
      padding: 10px 0px 0px 0px
  .format-start-date-end-date-null
    padding-top: 31px !important

.ui-datepicker
  font-size: 20% !important
  width: 20px
  height: 40px