.nav-menu
  padding-top: 13px

.user-bar
  position: relative
  padding-top: 20px
  padding-right: 70px
  .user-title
    padding-right: 10px

.tab-bar
  min-height: 40px
  background-color: $primary-dark-grey

.nav-logo
  max-width: 115px
  max-height: 115px
  padding: 10px