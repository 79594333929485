@mixin triangle ($size, $color, $direction) 
  height: 0
  width: 0

  $width: nth($size, 1)
  $height: nth($size, length($size))

  $foreground-color: nth($color, 1)
  $background-color: transparent !default
  @if (length($color) == 2) 
    $background-color: nth($color, 2)
  

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) 

    $width: $width / 2

    @if $direction == up 
      border-left: $width solid $background-color
      border-right: $width solid $background-color
      border-bottom: $height solid $foreground-color

    @else if $direction == right 
      border-top: $width solid $background-color
      border-bottom: $width solid $background-color
      border-left: $height solid $foreground-color

    @else if $direction == down 
      border-left: $width solid $background-color
      border-right: $width solid $background-color
      border-top: $height solid $foreground-color

    @else if $direction == left 
      border-top: $width solid $background-color
      border-bottom: $width solid $background-color
      border-right: $height solid $foreground-color
    
  

  @else if ($direction == up-right) or ($direction == up-left) 
    border-top: $height solid $foreground-color

    @if $direction == up-right 
      border-left:  $width solid $background-color

    @else if $direction == up-left 
      border-right: $width solid $background-color
    
  

  @else if ($direction == down-right) or ($direction == down-left) 
    border-bottom: $height solid $foreground-color

    @if $direction == down-right 
      border-left:  $width solid $background-color

    @else if $direction == down-left 
      border-right: $width solid $background-color
    
  

  @else if ($direction == inset-up) 
    border-width: $height $width
    border-style: solid
    border-color: $background-color $background-color $foreground-color
  

  @else if ($direction == inset-down) 
    border-width: $height $width
    border-style: solid
    border-color: $foreground-color $background-color $background-color
  

  @else if ($direction == inset-right) 
    border-width: $width $height
    border-style: solid
    border-color: $background-color $background-color $background-color $foreground-color
  

  @else if ($direction == inset-left) 
    border-width: $width $height
    border-style: solid
    border-color: $background-color $foreground-color $background-color $background-color

@mixin flex
  display: -webkit-box
  display: -moz-box
  display: -ms-flexbox
  display: -webkit-flex
  display: flex
  justify-content: center
  align-items: center
