.calendar
  width: 100%
  height: auto
  margin: 25px 0 30px
.day-name
  width: 14.28%
  text-align: center
  height: 30px
  background-color: #D4212A
  padding: 7px 0
  color: $primary-extra-light-gray
.day
  border: 1px solid $primary-light-grey
  height: 64px
  text-align: center
  cursor: pointer
  font-weight: 600
  font-size: 22px
  color: #7B7B7B
  outline-offset: -2px
  &:hover
    outline: 2px solid #FF5F66
.day-buffer
  opacity: 0.6
.day-not-available
  background-color: #B2B2B2
  color: $primary-white
.margin-bottom-30
  margin-bottom: 30px
.error-text
  color: $primary-red
.daily-breakfast-price
  font-weight: 500
  width: 14%
  text-align: right
  position: absolute
  font-size: 12px
  padding-right: 3px
.available-day-price
  color: #969696
.unavailable-day-price
  color: $table-light-grey
.breakfast-price-input
  margin-top: 15px
  div
    label
      margin-top: 14px
.breakfast-price-field
  position: relative
  input
    text-align: right
.half-width
  width: 50% !important
.euro-icon
  position: absolute
  top: 60%
  left: 13px
  margin-top: -10px
  font-size: 23px
.dialog-euro-icon
  position: absolute
  top: 4px
  left: 15px
  font-size: 23px
.row-display
  display: flow-root
.margin-top-15
  margin-top: 15px
.no-margin
  margin: 0 !important
.weekdays-checkbox
  padding: 0 5px
  margin: 10px 0
  input:focus
    outline: none