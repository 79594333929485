$border-color: #EEEEEE
$table-head-bg: #EBE7DF
$form-height: 40px
label
  color: #424F68
  font-weight: 600
  font-size: 12pt
  margin-top: 10px
  margin-bottom: 5px
input, select
  height: 40px
  border: 1px solid $input-border
select
  text-align: left
.custom-select
  height: $form-height
  border: 1px solid $border-color
.custom-input
  width: 100%
  font-size: 14pt
  height: $form-height
  border-radius: 5px
.custom-textarea
  height: auto
  resize: none
.custom-radio
  position: relative
  margin: 20px 0
  input
    position: absolute
    opacity: 0
    cursor: pointer
    width: 100%
    height: 100%
  .radio-box
    border-radius: 5px
    text-align: center
    padding: 6px 2px
    font-size: 14pt
    border: 1px solid $border-color
  input:checked ~ .radio-box
    pointer-events: none
    border-color: $primary-green
    color: $primary-green
    pointer-events: none

.box-selector
  display: flex
  border: 1px solid $primary-green
  border-radius: 5px
  height: 40px
  overflow: hidden
  .custom-radio
    margin: 0
    flex: 1 1 auto
    .radio-box
      border-radius: 0
      border: none
      color: $primary-green
      border-right: 1px solid $primary-green
      padding: 0 10px
      height: 100%
      display: grid
      place-items: center
    input:checked ~ .radio-box
      background-color: $primary-green
      border-right-color: #fff
      color: #fff
    &:last-child
      .radio-box
        border-right: none
      input:checked ~ .radio-box
        border-right-color: $primary-green
// .datepicker-input
//   position: relative
//   input
//     width: 100%
//     font-size: 14pt
//     height: $form-height
//     vertical-align: middle
//     border-radius: 5px!important
//     text-align: left!important
//     border: 1px solid $input-border
//     &.invalid
//       border-color: #FE6232 !important
//       background-color: #FFEBE5
//       color: #FE6232
//   button
//     position: absolute
//     top: 0
//     right: 0
//     width: $form-height
//     height: $form-height
//     border: none
//     border-radius: 0 5px 5px 0
//     background-color: $primary-green
//     color: #fff
//     fill: #fff
.select-menu
  width: 100%
  background-color: #fff
  position: relative
  .menu-list
    border: 1px solid $input-border
    border-radius: 5px
    padding: 5px
    padding-right: 15px
    cursor: pointer
    height: $form-height
    .menu-selected
      text-align: left
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .menu-arrow
      position: absolute
      top: 11px
      right: 10px
      .custom-fa
        position: relative
        top: -4px
        width: 15px!important
        svg
          width: 15px
    &::after
      display: none
  .menu-content
    background-color: #fff
    border-radius: 5px
    box-shadow: 0px 0px 10px 0px #61616175
    .normal-checkbox
      padding: 5px 10px
      &:hover
        background-color: #F8F7F2
      &.child
        padding-left: 40px
    .content-search
      padding: 10px
      input
        height: auto!important
        padding: 5px 10px
        width: 100%
        box-sizing: border-box
        background-color: #f1f0f0
        font-size: 14pt
    .content-not-found
      text-align: center
      padding: 25px 10px
      font-size: 12pt
      font-weight: 600
    .content-item
      padding: 5px 10px
      font-weight: 400
      font-size: 12pt
      cursor: pointer
      &:hover
        background-color: #ebebeb
.filter-content
  display: inline-block
  margin-right: 5px
  .filter-item
    display: flex
    gap: 5px
    align-items: center
    border: 1px solid $text-color
    padding: 0 8px
    border-radius: 20px
    background: #FFFFFF
    font-size: 10pt
    height: 28px
    .custom-fa
      text-align: center
      flex: 0 0 11.25px
      width: 11.25px!important
      top: -1px
      svg
        fill: $primary-red
        height: 15px!important
.custom-table
  width: 100%
  box-sizing: border-box
  border: none
  th, td
    border: none !important
    vertical-align: middle
  thead
    background-color: $table-head-bg !important
    color: #424F68
    top: 0
    z-index: 10
    border-top: none !important
    border-left: none !important
    th
      border-left: 1px solid rgba(235, 231, 223, 0.35) !important
      border-top: none !important
      border-right: 1px solid #EEEEEE !important
      .padding
        padding: 10px
        box-sizing: border-box
      &.sortActive
        background-color: #bfcbe6
        color: #424F68
      .flex-container
        display: flex
        flex-direction: row
        align-items: center
        .table-sort
          cursor: pointer
        .table-title
          flex-grow: 2
      
  tbody
    tr, td
      border: 1px solid $border-color !important
      border-top: none !important
    tr
      border-top: none !important
    tr
      &.graybg
        background-color: #f8f8f8
        color: #949494
      &:hover
        background-color: #ebebeb
        cursor: pointer
    td
      padding: 10px
      vertical-align: top
  .table-sort
    height: 40px
    width: 40px
    border-radius: 20px
    display: grid
    place-items: center
    &:hover
      background-color: $input-border
  .table-checkbox
    text-align: center
    height: 48.84px
    display: grid
    place-items: center
    .normal-checkbox
      display: inline-flex
.normal-checkbox
  position: relative
  display: flex
  align-items: center
  gap: 10px
  &.child
    padding-left: 40px!important
  input
    position: absolute
    opacity: 0
    cursor: pointer
    width: 100%!important
    height: 100%!important
    top: 0
    left: 0
    margin: 0
  .checkbox-box
    border: 2px solid $primary-green
    display: grid
    place-items: center
    vertical-align: middle
    width: 22px
    height: 22px
    flex: 0 0 22px
    color: white
    border-radius: 5px
    text-align: center
    position: relative
    pointer-events: none
    background-color: #fff
    fill: none
    fa-icon
      line-height: 0
    .custom-fa
      width: 14px!important
      top: 0px
  .checkbox-label
    font-size: 12pt
    pointer-events: none
  &.no-label
    .checkbox-box
      margin-right: 0
  input:checked ~ .checkbox-box
    background-color: $primary-green
    fill: #fff
  &.radio-style
    .checkbox-box
      border-radius: 50%
      padding: 4px
      .radio-icon
        width: 100%
        height: 100%
        background-color: white
        border-radius: 50%
  &.disabled
    opacity: 0.8
    pointer-events: none
.checkbox-list
  .normal-checkbox
    margin-bottom: 10px
.mui-table
  overflow-x: auto
  table
    border-collapse: collapse
    width: 100%
    td, th
      border: 1px solid $table-border
      padding: 10px 15px
      word-wrap: break-word
    thead
      tr
        th
          background-color: $table-head-bg
    .center-icon
      text-align: center
      .normal-checkbox
        display: inline-flex
        .checkbox-box
          top: -2px
          margin: 0
      .icon-only
        padding: 0
        .custom-fa
          svg
            max-height: 30px