@import "variables"
  
@mixin ellipsis
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  
html
  position: relative
  min-height: 100%

body
  margin-bottom: 102px
  font-family: "Soleil",-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"!important
  font-size: 16px
  line-height: 1.42857
  color: $text-color
  fill: $text-color
  background-color: $primary-white
  -webkit-font-smoothing: antialiased
  overflow-anchor: none
  &.disable-scroll
    overflow: hidden!important

::ng-deep .tooltip
  font-family: "Soleil",-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"!important

.mobile-hide
  @include media(xs)
    display: none
.tablet-hide
  @include media(sm-min)
    display: none

a
  color: $font-primary-grey
  &:hover
    color: $font-primary-black
    cursor: pointer
  &:focus
    color: $primary-dark-red
    text-decoration: underline
    outline: none
  &.underline
    text-decoration: underline

ul
  list-style: none
  padding: 0

.toast-top-right-custom
  top: 160px
  right: 12px
  @media (max-width: 749px)
    top: 12px
.blur
  opacity: 0.7

.blur-more
  opacity: 0.4

.clickable
  cursor: pointer

.main-container:not(.login-page), .wrapper
  min-height: 800px !important

.text-right
  text-align: right

.modal-dialog
  .close
    position: absolute
    right: 15px
    background: transparent
    border: none
    font-size: 16pt
    color: #285557

@keyframes rotation
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)


.loading-spinner
  width: 100px
  height: 100px
  border: 5px solid $primary-green
  border-bottom-color: transparent!important
  border-right-color: transparent!important
  border-radius: 50%
  display: inline-block
  box-sizing: border-box
  animation: rotation 0.5s linear infinite